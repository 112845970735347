import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const MemberDashboardOwnerInfo = ({ memid }) => {
  const navigate = useNavigate();
  const [memberData, setMemberData] = useState({
    name: "",
    contact: "",
    email: "",
    address: "",
    gender: "",
    emergencyContact: "",
    id: "",
    startDate: "",
    endDate: "",
    membership: "",
    image: null,
  });

  useEffect(() => {
    const currMem = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/gym/show-member-by-id/${memid}`
        );
        setMemberData(res.data.member);
        console.log(res);
      } catch (error) {
        console.error("Error fetching member data:", error);
      }
    };
    currMem();
  }, [memid]);
  return (
    <div className="dm-main">
      <div className="dm-nav">
        <div className="dm-navtop">
          <h1 id="dm-navtop-h1"> Dashboard </h1>
          <button
            id="dm-navtop-logout"
            onClick={() => {
              document.cookie =
                "gymId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
              return navigate("/");
            }}
          >
            Logout
          </button>
        </div>
        <div className="dm-navbottom">
          <div className="dm-navbottom-left">
            <div>
              <img
                src={memberData.displayImage}
                alt="profile"
                id="dm-navbottom-left-image"
              />
            </div>
            <span id="dm-navbottom-left-name">{memberData.name}</span>
            <div></div>
          </div>
          <div className="dm-navbottom-right">
            <div className="dm-navbottom-right-top">
              <div className="dm-navbottom-right-top-id-main">
                <span id="dm-navbottom-right-top-id"> ID</span>
                <span id="dm-navbottom-right-top-id-value">
                  {memberData.id}
                </span>
              </div>
              <div className="dm-navbottom-right-top-contact-main">
                <span id="dm-navbottom-right-top-contact"> Contact</span>
                <span id="dm-navbottom-right-top-contact-value">
                  {memberData.contact}
                </span>
              </div>
              <div className="dm-navbottom-right-top-email-main">
                <span id="dm-navbottom-right-top-email"> Email</span>
                <span id="dm-navbottom-right-top-email-value">
                  {memberData.email}
                </span>
              </div>
              <div className="dm-navbottom-right-top-emergency-main">
                <span id="dm-navbottom-right-top-emergency">Emergency No.</span>
                <span id="dm-navbottom-right-top-emergency-value">
                  {memberData.emergencyContact}
                </span>
              </div>
            </div>
            <div className="dm-navbottom-right-bottom">
              <div className="dm-navbottom-right-bottom-plan-main">
                <span id="dm-navbottom-right-bottom-id"> Plan</span>
                <span id="dm-navbottom-right-bottom-id-value">
                  {memberData.membershipPlan}
                </span>
              </div>
              <div className="dm-navbottom-right-bottom-startDate-main">
                <span id="dm-navbottom-right-bottom-startDate">
                  {" "}
                  Start Date
                </span>
                <span id="dm-navbottom-right-bottom-startDate-value">
                  {memberData.startDate}
                </span>
              </div>
              <div className="dm-navbottom-right-bottom-endDate-main">
                <span id="dm-navbottom-right-bottom-endDate"> End Date</span>
                <span id="dm-navbottom-right-bottom-endDate-value">
                  {memberData.endDate}
                </span>
              </div>
              <div className="dm-navbottom-right-bottom-address-main">
                <span id="dm-navbottom-right-bottom-address"> Address</span>
                <span id="dm-navbottom-right-bottom-address-value">
                  {memberData.address}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dm-middle">
        <Link to={`/member-attendance-information/${memid}`} style={{textDecoration:"none"}}>
          <div className="dm-middle-attendance-button">Attendance Details</div>
        </Link>
        <Link to={`/member-fees-information/${memid}`} style={{textDecoration:"none"}}>
          <div className="dm-middle-payment-button">Payment Details</div>
        </Link>
      </div>
      <div
        style={{
          flex: 1,
          height: "1px",
          backgroundColor: "gray",
          margin: "40px 0px",
        }}
      />
    </div>
  );
};

export default MemberDashboardOwnerInfo;
