import React from "react";
import "../styles/footer.css";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-container'>
        {/* About Us Section */}
        <div className='footer-section about'>
          <h3>About Us</h3>
          <p>
            Logit simplifies gym management so you can focus on fitness. Our
            platform streamlines operations, helping gym owners deliver
            exceptional experiences to their members.
          </p>
          <p>
            <strong>Logit is a product of Bramavi Technologies Pvt Ltd.</strong>
          </p>
        </div>

        {/* Contact Us Section */}
        <div className='footer-section contact'>
          <h3>Contact Us</h3>
          <p>Email: logitprivatelimited@gmail.com </p>
          <p>Phone: +91 85869 48252</p>
          <p>Address: Delhi, India</p>
        </div>

        {/* Follow Us Section */}
        <div className='footer-section social'>
          <h3>Follow Us</h3>
          <div className='social-icons'>
            <a
              href='https://www.facebook.com/people/Logit/61573105347977/https://www.facebook.com/people/Logit/61573105347977/'
              target='_blank'
              rel='noreferrer'
            >
              <FaFacebook />
            </a>
            <a href='/' target='_blank' rel='noreferrer'>
              <FaTwitter />
            </a>
            <a
              href='https://www.instagram.com/fit.logit/'
              target='_blank'
              rel='noreferrer'
            >
              <FaInstagram />
            </a>
            <a
              href='https://www.linkedin.com/company/wearelogit/'
              target='_blank'
              rel='noreferrer'
            >
              <FaLinkedin />
            </a>
          </div>

          {/* Write to Us Section */}
          <div className='write-to-us'>
            <h3>Write to Us</h3>
            <a
              href='https://mail.google.com/mail/?view=cm&fs=1&to=support@logit.com'
              target='_blank'
              rel='noreferrer'
              className='email-link'
            >
              <FaEnvelope /> support@logit.com
            </a>
          </div>
        </div>
      </div>

      <div className='footer-bottom'>
        <p>
          &copy; {new Date().getFullYear()} Logit by Bramavi technologies pvt
          ltd | All rights reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
