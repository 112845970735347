import React from "react";
import logo_owner from "../assets/logo_owner.jpg"
import logo_member from "../assets/logo_member.jpg"
import playstore from "../assets/PlayStoreDownload.jpg"
import appstore from "../assets/AppStoreDownload.jpg"
import logit_gym_owner_image from "../assets/Logit_gym_owner_image.jpg"
import logit_gym_members_image from "../assets/Logit_gym_members_image.jpg"


const AppLink = () => {
  return (
    <div className="app-link-section">
      <h2 id="app-link-title">Download The Apps</h2>
      <div className="app-container">
        {/* Gym Partner App */}
        <div className="app-box">
          <img src={logit_gym_owner_image} alt="Logit Gym Partner" id="app-image-owner" />
          <img src={logo_owner} alt="Logit Gym Partner Logo" className="app-logo" />
          <h3 className="app-name">Logit Gym Partner</h3>
          <p className="app-desc">For gym owners</p>
          <div className="store-links">
            <a href="https://shorturl.at/QUnD4" target="_blank" rel="noopener noreferrer">
              <img src={playstore} alt="Google Play" className="store-icon-gp" />
            </a>
            <a href="https://shorturl.at/cd7Ts" target="_blank" rel="noopener noreferrer">
              <img src={appstore} alt="App Store" className="store-icon" />
            </a>
          </div>
        </div>

        {/* Gym Members App */}
        <div className="app-box">
          <img src={logit_gym_members_image} alt="Logit Gym Member" id="app-image-member" />
          <img src={logo_member} alt="Logit Gym Member Logo" className="app-logo" />
          <h3 className="app-name">Logit</h3>
          <p className="app-desc">For gym members</p>
          <div className="store-links">
            <a href="https://shorturl.at/eIU2q" target="_blank" rel="noopener noreferrer">
              <img src={playstore} alt="Google Play" className="store-icon-gp" />
            </a>
            <a href="https://shorturl.at/xWRlD" target="_blank" rel="noopener noreferrer">
              <img src={appstore} alt="App Store" className="store-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLink;
